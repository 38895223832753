import React from 'react'
import './BottomBanner.modul.scss'
import { storImagePath } from '../../../../../../utils/Glob_Functions/GlobalFunction'

const BottomBanner = () => {
   
    return (
        <div className='stam_BottomBannerMain'>
            
        </div>
    )
}

export default BottomBanner;